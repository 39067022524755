export default class validate {
    static email(text) {
        if (required(text)) return required(text);
        const errors = [];

        if (!text.match(/\S+@\S+\.\S+/)) {
            errors.push('Не верный формат');
        }

        return response(errors);
    }
    static count(text, min = 1) {
        if (required(text)) return required(text);
        const errors = [];

        try {
            const num = parseInt(text);
            if (num < min) {
                errors.push('Число должно быть больше ' + min);
            }
        } catch (e) {
            errors.push('Не верный формат');
        }

        return response(errors);
    }

    static loginOrEmail(text) {
        if (required(text)) return required(text);
        const errors = [];

        if (!text.match(/\S+@\S+\.\S+/) && !text.match(/^\w+$/i)) {
            errors.push('Не верный формат');
        }

        return response(errors);
    }

    static login(text) {
        if (required(text)) return required(text);
        let errors = [];

        // Minimal chars
        const minError = min(text, 3);
        if (minError) errors = errors.concat(minError);

        // Maximal charts
        const maxError = max(text, 16);
        if (maxError) errors = errors.concat(maxError);

        // Format name
        if (!text.match(/^\w+$/i)) {
            errors.push('Не верный формат');
        }

        return response(errors);
    }

    static password(text) {
        if (required(text)) return required(text);
        let errors = [];

        // Minimal chars
        const minError = min(text, 6);
        if (minError) errors = errors.concat(minError);

        // Maximal charts
        const maxError = max(text, 120);
        if (maxError) errors = errors.concat(maxError);

        // Format pass
        if (!text.match(/^[A-Za-z0-9_@#$%^&+=*!+-~()]*$/i)) {
            errors.push('Не верный формат');
        }

        return response(errors);
    }

    static name(text) {
        if (required(text)) return false;
        let errors = [];

        if (!text.match(/^[a-zа-я]*$/i)) {
            errors.push('Не верный формат');
        }

        return response(errors);
    }

    static website(text) {
        if (required(text)) return false;
        let errors = [];

        if (!text.match(/\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#/%?=~_|!:,.;]*[-a-z0-9+&@#/%=~_|]/i)) {
            errors.push('Не верный формат');
        }

        return response(errors);
    }

    static skin(img) {
        let errors = [];

        if (img.width / img.height !== 1 &&
            img.width / img.height !== 2) {
            errors.push('Неверный формат изображения');
        }

        if (img.width < 64 || img.width > 4096 ||
                img.height < 32 || img.height > 4096) {
            errors.push('Неверный размер изображения');
        }

        return response(errors);
    }

    static banner(img) {
        let errors = [];
        if (img.width < 512 || img.width > 4096 ||
            img.height < 256 || img.height > 4096) {
            errors.push('Неверный размер изображения');
        }

        if (img.width / img.height < 1.7) {
            errors.push('Неверный формат изображения');
        }

        return response(errors);
    }

    static avatar(img) {
        let errors = [];

        if (img.width / img.height < 0.75) {
            errors.push('Неверный формат изображения');
        }

        if (img.width < 256 || img.width > 4096 ||
            img.height < 256 || img.height > 4096) {
            errors.push('Неверный размер изображения');
        }

        return response(errors);
    }

    static cape(img) {
        let errors = [];

        if (img.width / img.height !== 2) {
            errors.push('Неверный формат изображения');
        }

        if (img.width < 64 || img.width > 4096 ||
            img.height < 32 || img.height > 4096) {
            errors.push('Неверный размер изображения');
        }

        return response(errors);
    }

    static confirm(text, original) {
        if (required(text)) return required(text);
        if (text !== original) return [ 'Поле не совпадает с оригиналом' ];

        return false;
    }
}

function max(text, max) {
    if (text && text.length <= max) {
        return false;
    }

    return [ `Максимальное кол-во символов ${max}` ];
}

function min(text, min) {
    if (text && text.length >= min) {
        return false;
    }

    return [ `Минимальное кол-во символов ${min}` ];
}

function required(text) {
    if (!text || text.length < 1) {
        return [ 'Это обязательное поле' ];
    }
    return false;
}

function response(errors) {
    if (!errors) {
        return false;
    }

    if (errors.length < 1) {
        return false;
    }

    return errors;
}